<template>
  <div class="nav-bar-container">
    <el-row :gutter="15">
      <el-col :xs="4" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="left-panel">
          <i v-if="visitedRoutes.length > 0" class="iconfont icon-menu1"></i>
          <breadcrumb />
        </div>
      </el-col>
      <el-col :xs="20" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="right-panel">
          <avatar />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
    };
  },
  computed: {
    collapse() {
      return this.$store.state.collapse;
    },
    visitedRoutes() {
      return this.$store.state.visitedRoutes;
    },
    routes() {
      return this.$store.state.routes;
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.nav-bar-container {
  position: relative;
  height: 60px;
  padding-right: 20px;
  padding-left: 20px;
  overflow: hidden;
  user-select: none;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .left-panel {
    display: flex;
    align-items: center;
    justify-items: center;
    height: 60px;

    .fold-unfold {
      color: rgba(0, 0, 0, 0.65);
      cursor: pointer;
    }

    ::v-deep {
      .breadcrumb-container {
        margin-left: 10px;
      }
    }
  }

  .right-panel {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    height: 60px;

    ::v-deep {
      svg {
        width: 1em;
        height: 1em;
        margin-right: 15px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        cursor: pointer;
        fill: rgba(0, 0, 0, 0.65);
      }

      button {
        svg {
          margin-right: 0;
          color: #fff;
          cursor: pointer;
          fill: #fff;
        }
      }

      .el-badge {
        margin-right: 15px;
      }
    }
  }
}
</style>
