<template>
  <el-dropdown @command="handleCommand">
    <span class="avatar-dropdown">
      <!-- <img class="user-avatar" :src="avatar" alt="" /> -->
      <i class="user-avatar el-icon-s-custom"></i>
      <div class="user-name">
        {{ userName }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </div>
    </span>

    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="changePassword">修改密码</el-dropdown-item>
      <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import Service from "@/utils/service";
import Util from "@/utils/util";
import ChangePassword from "@/views/account/ChangePassword.vue";
export default {
  name: "Avatar",

  computed: {
    avatar() {
      return "https://i.gtimg.cn/club/item/face/img/8/15918_100.gif";
    },
    userName() {
      return this.$store.state.loginUser;
    },
  },
  data() {
    return {};
  },

  methods: {
    handleCommand(command) {
      switch (command) {
        case "logout":
          this.logout();
          break;
        case "changePassword":
          this.changePassword();
          break;
      }
    },

    personalCenter() {
    },
    async logout() {
      await this.$confirm("您确定要退出" + this.$baseTitle + "吗?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })

      Util.removeToken();
      this.$store.commit("reset");
      this.$router.push("/login");
    },
    changePassword() {
      var dialog = Util.createComponent(ChangePassword, {}, document.querySelector("#app"));
      dialog.open();
    },
  },
  created() { },
};
</script>
<style lang="scss" scoped>
.avatar-dropdown {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  height: 20px;
  padding: 0;

  .user-avatar {
    // width: 40px;
    // height: 40px;
    // cursor: pointer;
    // border-radius: 50%;
    font-size: 24px;
  }

  .user-name {
    position: relative;
    margin-left: 5px;
    margin-left: 5px;
    cursor: pointer;
  }
}
</style>
