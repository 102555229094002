<template>
  <el-breadcrumb class="breadcrumb-container" separator=">">
    <el-breadcrumb-item v-for="item in list" :key="item.path">
      {{ item }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      list: this.getBreadcrumb(),
    };
  },

  watch: {
    $route() {
      this.list = this.getBreadcrumb();
    },
    "$store.state.menu": {
      handler() {
        this.list = this.getBreadcrumb();
      }
    }
  },
  methods: {
    getBreadcrumb() {
      let path = this.$route.path;
      return this.$store.state.components.find(v => v.path === path)?.fullTitle ?? [];
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb-container {
  height: 60px;
  font-size: 14px;
  line-height: 60px;

  ::v-deep {
    .el-breadcrumb__item {
      .el-breadcrumb__inner {
        a {
          display: flex;
          float: left;
          font-weight: normal;
          color: #515a6e;

          i {
            margin-right: 3px;
          }
        }
      }

      &:last-child {
        .el-breadcrumb__inner {
          a {
            color: #999;
          }
        }
      }
    }
  }
}
</style>
