import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    collapse: false,
    visitedRoutes: [],
    components: [],
    menu: [],
    loginUser: null,
    countryList: [
      { Id: 1, Name: "美国", EnglishName: "USA", currency: 2 },
      { Id: 2, Name: "英国", EnglishName: "GBR", currency: 6 },
      { Id: 3, Name: "日本", EnglishName: "JPN", currency: 4 },
      { Id: 4, Name: "德国", EnglishName: "GER", currency: 3 },
      { Id: 5, Name: "法国", EnglishName: "FRA", currency: 3 },
      { Id: 6, Name: "欧盟", EnglishName: "EU", currency: 3 },
      { Id: 7, Name: "加拿大", EnglishName: "CAN", currency: 5 },
      { Id: 8, Name: "中国", EnglishName: "CN", currency: 1 },
    ],
    categoryList: [
      { Id: 1, Name: "电子-Electronics" },
      { Id: 2, Name: "电脑周边-Computers" },
      { Id: 3, Name: "智慧家庭设备-Smart Home" },
      { Id: 4, Name: "家居花园工具-Home Garden&Tools" },
      { Id: 5, Name: "宠物用品-Pet Supplies" },
      { Id: 6, Name: "食品-Food&Grocery" },
      { Id: 7, Name: "美妆健康-Beauty&Health" },
      { Id: 8, Name: "玩具-Toys Kids & Baby" },
      { Id: 9, Name: "手工品-Handmade" },
      { Id: 10, Name: "运动品-Sports" },
      { Id: 11, Name: "户外-Outdoors" },
      { Id: 12, Name: "汽车&工业用品-Automotive&Industrial" },
    ],
    currencyList: [
      { Id: 1, Name: "人民币" },
      { Id: 2, Name: "美元" },
      { Id: 3, Name: "欧元" },
      { Id: 4, Name: "日元" },
      { Id: 5, Name: "加元" },
      { Id: 6, Name: "英镑" },
    ],
  },
  getters: {},
  mutations: {
    setAccountInfo(state, loginUser) {
      state.loginUser = loginUser;
    },

    addVisitedRoute(state, route) {
      let path = route.path;
      let target = state.visitedRoutes.find((item) => item.path === path);
      if (target) {
        return;
      }
      target = Object.assign({}, route);
      target.name = route.matched[route.matched.length - 1].components.default.name;
      target.meta.title = state.components.find((v) => v.path == path)?.title;
      state.visitedRoutes.push(target);
    },

    removeVisitedRoute(state, index) {
      let routes = state.visitedRoutes;
      if (index > -1) {
        routes.splice(index, 1);
      }
    },

    removeLeftRoutes(state, path) {
      let routes = state.visitedRoutes;
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].path == path) {
          return;
        } else {
          routes.splice(i, 1);
          i--;
        }
      }
    },

    removeRightRoutes(state, path) {
      let routes = state.visitedRoutes;
      for (let i = routes.length - 1; i >= 0; i--) {
        if (routes[i].path == path) {
          return;
        } else {
          routes.splice(i, 1);
        }
      }
    },

    removeOtherRoutes(state, path) {
      let i = 0;
      let routes = state.visitedRoutes;
      while (i < routes.length) {
        if (routes[i].path != path) {
          routes.splice(i, 1);
        } else {
          i++;
        }
      }
    },

    removeAllRoutes(state) {
      let routes = state.visitedRoutes;
      for (let i = 0; i < routes.length; i++) {
        routes.splice(i, 1);
        i--;
      }
    },
    initMenu(state) {
      state.menu = [
        // {
        //   path: '/index',
        //   title: '首页',
        //   icon: 'el-icon-s-home',

        // },
        {
          path: "/user",
          title: "用户管理",
          icon: "el-icon-user-solid",
        },
        {
          path: "/product",
          title: "商品管理",
          icon: "el-icon-s-goods",
        },
        {
          path: "/bidding",
          title: "竞价管理",
          icon: "el-icon-s-opportunity",
        },
        {
          path: "/asktobuy",
          title: "求购管理",
          icon: "el-icon-s-cooperation",
        },
        {
          path: "/order",
          title: "订单管理",
          icon: "el-icon-s-order",
        },
        {
          path: "/audit/product",
          title: "商品审核",
          icon: "el-icon-s-claim",
        },
        {
          path: "/notice",
          title: "公告管理",
          icon: "el-icon-info",
        },
      ];
      let search = (menu, parent) => {
        menu.forEach((item) => {
          if (parent) {
            item.fullTitle = [...parent.fullTitle, item.title];
          } else {
            item.fullTitle = [item.title];
          }

          if (item.children?.length > 0) {
            if (parent) {
              item.path = parent.path + item.path;
            }
            search(item.children, item);
          } else {
            state.components.push({
              title: item.title,
              path: item.path,
              fullTitle: item.fullTitle,
            });
          }
        });
      };
      search(state.menu, null);
    },
    reset(state) {
      state.visitedRoutes = [];
      state.menu = [];
    },
  },
  actions: {},
  modules: {},
});
