import '@/assets/css/app.scss'
import '@/assets/font/iconfont.css'

const install = (Vue, opts = {}) => {
    Vue.prototype.$baseTitle = process.env.VUE_APP_TITLE;
    Vue.directive('focus', {
        inserted(el, binding, vnode) {
            if (binding.value || !Object.prototype.hasOwnProperty.call(binding, 'value')) {
                el.children[0].focus();
            }
            else {
                return
            }
        },
    });

}

if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue)
}

export default install
