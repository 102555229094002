<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Service from "@/utils/service";
import Util from "@/utils/util";

export default {
  name: "App",
  data() {
    return {};
  },
  methods: {
    async getAccountInfo() {
      let result = null;

      try {
        result = await Service.getAccountInfo();
      } catch {
        this.$store.commit("reset");
        return;
      }

      this.$store.commit("reset");
      this.$store.commit("setAccountInfo", result.Name);
      this.$store.commit("initMenu");
      if (this.$route.path == "/") {
        let search = (menu) => {
          let target = "";
          for (let i = 0; i < menu.length; i++) {
            if (menu[i]?.children) {
              target = search(menu[i].children);
            } else {
              target = menu[i].path;
              break;
            }
          }
          return target;
        };
        if (this.$store.state.menu.length > 0) {
          this.$router.push(search(this.$store.state.menu));
        }
      } else {
        this.$store.commit("addVisitedRoute", this.$route);
      }
    },
  },
  created() {
    if (Util.getToken()) {
      this.getAccountInfo();
    }
  },

  watch: {
    $route: {
      handler(to, from) {
        if (from?.path == "/login" && !to.meta.anonymous) {
          this.getAccountInfo();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
