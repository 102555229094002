<template>
  <el-scrollbar class="side-bar-container">
    <logo />
    <el-menu :background-color="variables['menu-background']" :text-color="variables['menu-color']"
      :active-text-color="variables['menu-color-active']" :default-active="defaultActive" :collapse="false"
      :collapse-transition="true" :default-openeds="defaultOpeneds" :unique-opened="uniqueOpened" mode="vertical">
      <side-bar-item v-for="item in menu" :key="item.path" :path="item.path" :item="item" />
    </el-menu>
  </el-scrollbar>
</template>
<script>
import variables from "@/assets/css/variables.module.scss";

export default {
  name: "SideBar",
  data() {
    return {
      uniqueOpened: true,
      defaultOpeneds: [],
    };
  },
  computed: {
    menu() {
      return this.$store.state.menu;
    },
    defaultActive() {
      return this.$route.path;
    },
    variables() {
      return variables;
    },
  },
  mounted() {

  },
  methods: {

  }
};
</script>
<style lang="scss" scoped>
@mixin active {
  &:hover {
    color: #fff;
    background-color: #0187fb !important;
  }

  &.is-active {
    color: #fff;
    background-color: #0187fb !important;
  }
}

.side-bar-container {
  width: 256px;
  overflow: hidden;
  background: #21252b;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  transition: width 0.3s;

  ::v-deep {
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    .el-menu {
      border: 0;

      .vab-fas-icon {
        padding-right: 3px;
        font-size: 14px;
      }

      .vab-remix-icon {
        padding-right: 3px;
        font-size: 14px + 2;
      }
    }

    .el-menu-item,
    .el-submenu__title {
      height: 50px;
      overflow: hidden;
      line-height: 50px;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
    }

    .el-menu-item {
      @include active;
    }
  }
}
</style>
