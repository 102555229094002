<template>
    <div>
        <el-dialog title="修改密码" :visible.sync="dialogVisible" width="500px" :close-on-click-modal="false">
            <el-form label-width="100px" @submit.native.prevent>
                <el-form-item label="原密码">
                    <el-input type="password" placeholder="请输入原密码" v-model="oldPassword" v-focus
                        autocomplete="new-password"></el-input>
                </el-form-item>
                <el-form-item label="新密码">
                    <el-input type="password" placeholder="请输入新密码" v-model="newPassword"></el-input>
                </el-form-item>
                <el-form-item label="确认密码">
                    <el-input type="password" placeholder="请输入新密码" v-model="verifyPassword"></el-input>
                </el-form-item>
                <el-form-item style="text-align: right;">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" native-type="submit" @click="submmit">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import Service from "@/utils/service";

export default {
    data() {
        return {
            dialogVisible: false,
            oldPassword: '',
            newPassword: '',
            verifyPassword: ''
        }
    },
    methods: {
        open() {
            this.dialogVisible = true;
        },
        async submmit() {
            if (this.newPassword == this.verifyPassword) {
                await Service.setPassword({
                    OldPassword: this.oldPassword,
                    NewPassword: this.newPassword
                });
                this.dialogVisible = false
                this.$message.success('密码修改成功')

            } else {
                this.$message.error('新密码和确认密码不一致')
            }
        }
    }
}

</script>