<template>
  <div class="app-wrapper">
    <div class="layout-container-vertical fixed">
      <side-bar />
      <div class="main">
        <div class="fixed-header">
          <nav-bar />
          <tabs-bar />
        </div>
        <app-main />
      </div>
    </div>
    <el-backtop />
  </div>
</template>

<script>
export default {
  name: "MainLayout",
  data() {
    return {
      oldLayout: "",

      show: true,
      keepAliveMaxNum: 100,
      routerView: true,
    };
  },

  computed: {
    visitedRoutes() {
      return this.$store.state.visitedRoutes;
    },
    cachedRoutes() {
      const cachedRoutesArr = [];
      this.visitedRoutes.forEach((item) => {
        if (!item.meta.noKeepAlive) {
          cachedRoutesArr.push(item.name);
        }
      });
      return cachedRoutesArr;
    },
    key() {
      return this.$route.path;
    },
  },
  beforeMount() {
  },
  beforeDestroy() {
  },
  mounted() {

  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@mixin fix-header {
  top: 0;
  right: 0;
  left: 0;
  // z-index: 999 - 2;
  width: 100%;
  overflow: hidden;
}

.app-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .layout-container-vertical {
    display: flex;
    position: relative;
    height: 100%;

    .mask {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      background: #000;
      opacity: 0.5;
    }

    &.fixed {
      padding-top: 0px;
    }

    &.fixed.no-tabs-bar {
      padding-top: 60px;
    }

    .main {
      width: 100%;
      position: relative;
      min-height: 100%;
      background: #f6f8f9;
      display: flex;
      flex-direction: column;

      ::v-deep {
        .fixed-header {
          @include fix-header;
          position: relative;
          width: 100%;
          box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
        }

        .nav-bar-container {
          position: relative;
          box-sizing: border-box;
        }

        .tabs-bar-container {
          box-sizing: border-box;
        }

        .app-main-container {
          display: flex;
          flex: 1;
          width: calc(100% - 40px);
          margin: 20px auto;
          background: #fff;
          border-radius: 4px;
        }
      }

      &.is-collapse-main {
        margin-left: 65px;

        ::v-deep {
          .fixed-header {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
