<template>
  <el-menu-item :index="item.path" @click="onClick">
    <i v-if="item.icon" :class="item.icon"></i>
    <span>{{ item.title }}</span>
  </el-menu-item>
</template>

<script>
import Util from "@/utils/util";
import Link from "@/utils/link";

export default {
  name: "MenuItem",
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  methods: {
    onClick() {
      const path = this.item.path;
      const target = this.item.target;
      const type = this.item.type;

      if (type === "link") {
        Link.open(this.$router, path, target);
      } else {
        if (target === "_blank") {
          if (Util.isAbsolutePath(path)) {
            window.open(path);
          } else {
            let routeData = this.$router.resolve(path);
            window.open(routeData.href);
          }
        } else {
          if (Util.isAbsolutePath(path)) {
            window.location.href = path;
          } else {
            this.$router.push(path);
          }
        }
      }
    },
  },
};
</script>
