<template>
  <div class="logo-container-vertical">
    <span class="title" :title="title">
      {{ title }}
    </span>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Logo",
  data() {
    return {
      title: this.$baseTitle,
    };
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
@mixin container {
  position: relative;
  height: 65px;
  overflow: hidden;
  line-height: 65px;
  background: #21252b;
}

@mixin logo {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-right: 3px;
  color: #fff;
  vertical-align: middle;
}

@mixin title {
  display: inline-block;
  overflow: hidden;
  font-size: 24px;
  line-height: 55px;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.logo-container-horizontal {
  @include container;

  .logo {
    @include logo;
  }

  .title {
    @include title;
  }
}

.logo-container-vertical {
  @include container;

  height: 75px;
  line-height: 75px;
  text-align: center;

  .logo {
    @include logo;
  }

  .title {
    @include title;

    max-width: calc(256px - 60px);
  }
}
</style>
