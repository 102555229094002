import Vue from "vue";
import VueRouter from "vue-router";
import MainLayout from "@/layouts/MainLayout";
import EmptyLayout from "@/layouts/EmptyLayout";
import Util from "@/utils/util";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("@/views/Login"),
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/",
    component: MainLayout,
  },
  {
    path: "/index",
    component: MainLayout,
    children: [
      {
        path: "/",
        component: () => import("@/views/index/Home"),
      },
    ],
  },
  {
    path: "/user",
    component: MainLayout,
    children: [
      {
        path: "/",
        component: () => import("@/views/user/UserList"),
      },
    ],
  },
  {
    path: "/asktobuy",
    component: MainLayout,
    children: [
      {
        path: "/",
        component: () => import("@/views/askToBuy/AskToBuyList"),
      },
    ],
  },
  {
    path: "/product",
    component: MainLayout,
    children: [
      {
        path: "/",
        component: () => import("@/views/product/ProductList"),
      },
    ],
  },
  {
    path: "/audit",
    component: MainLayout,
    children: [
      {
        path: "product",
        component: () => import("@/views/audit/AuditProductList"),
      },
    ],
  },
  {
    path: "/order",
    component: MainLayout,
    children: [
      {
        path: "/",
        component: () => import("@/views/order/OrderList"),
      },
    ],
  },
  {
    path: "/bidding",
    component: MainLayout,
    children: [
      {
        path: "/",
        component: () => import("@/views/bidding/BiddingList"),
      },
    ],
  },
  {
    path: "/401",
    component: () => import("@/views/401"),
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/404",
    component: () => import("@/views/404"),
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/notice",
    component: MainLayout,
    children: [
      {
        path: "/",
        component: () => import("@/views/notice/NoticeList"),
      },
    ],
  },
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true,
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta?.anonymous) {
    next();
  } else {
    if (Util.getToken()) {
      next();
    } else {
      next("/login");
    }
  }
});

router.afterEach((to, from) => {
  if (to.matched && to.matched.length > 2) {
    for (let i = 0; i < to.matched.length; i++) {
      if (to.matched[i].components.default.name === "EmptyLayout") {
        to.matched.splice(i, 1);
        i--;
      }
    }
  }

  var target = store.state.visitedRoutes.find((v) => v.path == to.path);
  if (target && to.matched?.length > 0) {
    target.name = to.matched[to.matched.length - 1].components.default.name;
  }
});

export default router;
