export default {
    open(router, code, target) {
        let path = "/index";
        let useRoute = true;
        if (code == '123') {
            path = '/user'
        }

        if (target === '_blank') {
            window.open(path)
        }
        else {
            if (useRoute) {
                router.push(path)
            }
            else {
                window.location.href = path
            }
        }
    }
}  