<template>
  <div id="tabs-bar-container" class="tabs-bar-container">
    <el-tabs v-model="activeName" type="card" class="tabs-content" @tab-click="onTabClick" @tab-remove="onTabRemove">
      <el-tab-pane v-for="item in visitedRoutes" :key="item.path" :label="item.meta.title" :name="item.path" closable>
      </el-tab-pane>
    </el-tabs>

    <el-dropdown @command="handleCommand">
      <span style="cursor: pointer"> 更多操作 <i class="el-icon-arrow-down el-icon--right"></i> </span>
      <el-dropdown-menu slot="dropdown" class="tabs-more">
        <el-dropdown-item command="closeOtherTabs"><i class="iconfont icon-roundclose"></i> 关闭其他 </el-dropdown-item>
        <el-dropdown-item command="closeLeftTabs"><i class="iconfont icon-pullleft"></i> 关闭左侧 </el-dropdown-item>
        <el-dropdown-item command="closeRightTabs"><i class="iconfont icon-pullright"></i> 关闭右侧 </el-dropdown-item>
        <el-dropdown-item command="closeAllTabs"><i class="iconfont icon-roundclosefill"></i> 关闭全部 </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "TabsBar",
  data() {
    return {
      activeName: "",
    };
  },

  computed: {
    visitedRoutes() {
      return this.$store.state.visitedRoutes;
    },
    routes() {
      return this.$store.state.route;
    },
  },
  watch: {
    $route: {
      handler(route) {
        this.handler();
      },
      immediate: true,
    },
    "$store.state.menu": {
      handler() {
        this.handler();
      },
    },
  },
  mounted() { },
  methods: {
    handler() {
      if (this.$route.path == "/" || this.$store.state.menu.length == 0) {
        return;
      }
      this.$store.commit("addVisitedRoute", this.$route);
      let index = this.visitedRoutes.findIndex((tab) => this.$route.path === tab.path);
      this.activeName = this.visitedRoutes[index].path;
    },
    onTabRemove(targetName) {
      let index = this.visitedRoutes.findIndex((tab) => targetName === tab.path);
      var next = this.visitedRoutes[index + 1] || this.visitedRoutes[index - 1];
      if (next) {
        if (this.$route.path != next.path) {
          this.$router.push(next.path);
        }
        this.activeName = next.path;
      }
      this.$store.commit("removeVisitedRoute", index);
      if (this.visitedRoutes.length == 0) {
        this.$router.push("/");
      }
    },
    onTabClick(tab, event) {
      let route = this.visitedRoutes[tab.index];
      if (route.path != this.$route.path) {
        this.$router.push(route.path);
      }
    },

    isActive(route) {
      return route.path === this.$route.path;
    },

    handleCommand(command) {
      switch (command) {
        case "closeOtherTabs":
          this.closeOtherTabs();
          break;
        case "closeLeftTabs":
          this.closeLeftTabs();
          break;
        case "closeRightTabs":
          this.closeRightTabs();
          break;
        case "closeAllTabs":
          this.closeAllTabs();
          break;
      }
      if (this.visitedRoutes.length == 0) {
        this.$router.push("/");
      }
    },

    closeOtherTabs() {
      this.$store.commit("removeOtherRoutes", this.$route.path);
    },

    closeLeftTabs() {
      this.$store.commit("removeLeftRoutes", this.$route.path);
    },

    closeRightTabs() {
      this.$store.commit("removeRightRoutes", this.$route.path);
    },

    closeAllTabs() {
      this.$store.commit("removeAllRoutes");
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-bar-container {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  padding-right: 20px;
  padding-left: 20px;
  user-select: none;
  background: #fff;
  border-top: 1px solid #f6f6f6;

  ::v-deep {
    .fold-unfold {
      margin-right: 20px;
    }
  }

  .tabs-content {
    width: calc(100% - 90px);
    height: 34px;

    ::v-deep {

      .el-tabs__nav-next,
      .el-tabs__nav-prev {
        height: 34px;
        line-height: 34px;
      }

      .el-tabs__header {
        border-bottom: 0;

        .el-tabs__nav {
          border: 0;
        }

        .el-tabs__item {
          box-sizing: border-box;
          height: 34px;
          margin-right: 5px;
          line-height: 34px;
          border: 1px solid #dcdfe6;
          border-radius: 4px;
          transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;

          &.is-active {
            border: 1px solid #0187fb;
          }
        }
      }
    }
  }

  .more {
    display: flex;
    align-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>
