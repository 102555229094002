import Request from "@/utils/request";

export default {
  login(data) {
    return Request.postJson("/api/Admin/Account/Login", data, false);
  },
  getAccountInfo() {
    return Request.get("/api/Admin/Account/GetAccountInfo");
  },

  report(data) {
    return Request.postJson("/api/Common/Report", data);
  },

  setPassword(data) {
    return Request.postJson("/api/Admin/Account/SetPassword", data, true);
  },
  // 上传图片
  uploadImage(data) {
    return Request.postForm("/api/Common/uploadimage", data);
  },
  // 上传订单图片
  addOrderImage(data) {
    return Request.postJson("/api/Admin/Order/AddOrderImage", data, true);
  },
  // 删除订单图片
  deleteOrderImage(data) {
    return Request.postJson("/api/Admin/Order/DeleteOrderImage", data, true);
  },
  //编辑订单
  editOrder(data) {
    return Request.postJson("/api/Admin/Order/EditOrder", data, true);
  },

  //User
  getUserList(data) {
    return Request.get("/api/Admin/User/GetUserList", data, true);
  },
  //自动登录
  getAutoLoginUrl(data) {
    return Request.get("/api/Admin/User/GetAutoLoginUrl", data, true);
  },

  getUserDetail(data) {
    return Request.get("/api/Admin/User/GetUserDetail", data, true);
  },
  editUser(data) {
    return Request.postJson("/api/Admin/User/EditUser", data, true);
  },
  setUserStatus(data) {
    return Request.postJson("/api/Admin/User/SetUserStatus", data, true);
  },

  // 产品
  getProductList(data) {
    return Request.get("/api/Admin/Product/GetProductList", data, true);
  },
  getAuditProdutList(data) {
    return Request.get("/api/Admin/Audit/GetProductList", data, true);
  },
  auditProduct(data) {
    return Request.postJson("/api/Admin/Audit/AuditProduct", data, true);
  },

  getProductDetail(data) {
    return Request.get("/api/Admin/Product/GetProductDetail", data, true);
  },

  editProduct(data) {
    return Request.postJson("/api/Admin/Product/EditProduct", data, true);
  },
  deleteProduct(data) {
    return Request.postJson("/api/Admin/Product/DeleteProduct", data, true);
  },
  setProductFlag(data) {
    return Request.postJson("/api/Admin/Product/SetProductFlag", data, true);
  },
  copyProduct(data) {
    return Request.postJson("/api/Admin/Product/CopyProduct", data, true);
  },
  // 订单
  getOrderList(data) {
    return Request.get("/api/Admin/Order/GetOrderList", data, true);
  },
  getOrderDetail(data) {
    return Request.get("/api/Admin/Order/GetOrderDetail", data, true);
  },
  editOrderAddress(data) {
    return Request.postJson("/api/Admin/Order/EditOrderAddress", data, true);
  },
  checkPayOrder(data) {
    return Request.postJson("/api/Admin/Order/CheckPayOrder", data, true);
  },
  //竞价
  getBiddingList(data) {
    return Request.get("/api/Admin/Bidding/GetBiddingList", data, true);
  },
  setBiddingFlag(data) {
    return Request.postJson("/api/Admin/Bidding/SetBiddingFlag", data, true);
  },
  getBiddingDetail(data) {
    return Request.get("/api/Admin/Bidding/GetBiddingDetail", data, true);
  },
  //公告
  GetNoticeList(data) {
    return Request.get("api/Admin/Notice/GetNoticeList", data, true);
  },
  GetNoticeDetail(data) {
    return Request.get("api/Admin/Notice/GetNoticeDetail", data, true);
  },
  editNotice(data) {
    return Request.postJson("api/Admin/Notice/EditNotice", data, true);
  },
  setNoticeStatus(data) {
    return Request.postJson("api/Admin/Notice/SetNoticeStatus", data, true);
  },
  setNoticeOrder(data) {
    return Request.postJson("api/Admin/Notice/SetNoticeOrder", data, true);
  },
  deleteNotice(data) {
    return Request.postJson("api/Admin/Notice/DeleteNotice", data, true);
  },

  //求购

  GetAskToBuyList(data) {
    return Request.get("api/Admin/AskToBuy/GetList", data, true);
  },

  GetAskToBuyDetail(data) {
    return Request.get("api/Admin/AskToBuy/GetDetail", data, true);
  },

  EditAskToBuy(data) {
    return Request.postJson("api/Admin/AskToBuy/Edit", data, true);
  },
  SetAskToBuyFlag(data) {
    return Request.postJson("api/Admin/AskToBuy/SetFlag", data, true);
  },
  DeleteAskToBuy(data) {
    return Request.postJson("api/Admin/AskToBuy/Delete", data, true);
  },
};
